import React, { useCallback } from "react";
import appConfig from "../../../../appConfig";
import { useAffiliatesStore } from "../../../../hooks/redux/affiliates";

const AffiliatePromoCard = () => {
  const isDC = appConfig.branding === "dc";
  const isRev = appConfig.branding === "rev";

  const { createAffiliate } = useAffiliatesStore();

  const onCreateAffiliate = useCallback(() => {
    createAffiliate();
  });

  const startNowBtn = (
    <a href="#" className="btn btn-primary" onClick={onCreateAffiliate}>
      START NOW
    </a>
  );

  return (
    <div className="card ">
      <div className="card-body">
        <h5 className="card-title">
          📣 Spread the word... and get rewarded through affiliates.
        </h5>
        <p className="card-text">
          Want to spread the word AND earn cash back for you or your{" "}
          {isDC ? "ministry" : "company"}?
        </p>
        <p className="card-text">
          <strong>🪙 Become a {appConfig.displayName} affiliate today!</strong>
        </p>
        <p className="card-text">
          We're a leading church event and group management platform, partnering
          with hundreds of houses of worship worldwide. Tap into our powerful
          referral network and earn up to 30% recurring commission on sales you
          drive. <br />
        </p>
        <h5>🚀 It's simple:</h5>
        <ul>
          <li>Sign up with your email</li>
          <li>Get your custom affiliate link</li>
          <li>Share it far and wide (email, social, church newsletter)</li>
          <li>Watch commissions roll in automatically</li>
          <li>Withdraw earnings anytime ($$$ sent via PayPal)</li>
        </ul>
        <p className="card-text">
          Track your referrals and commissions made right on your affiliate
          dashboard. Full transparency into all of your efforts. 📈
        </p>
        <p className="card-text">
          <strong>
            🤓 Best part? No need to be tech-savvy or have a huge following on
            social. Our platform sells itself.
          </strong>
        </p>
        {isDC && (
          <p className="card-text">
            Help churches connect with members while allowing you to earn cash
            for your personal or ministry needs.
          </p>
        )}
        {isRev && (
          <p className="card-text">
            Help businesses to reach more audience while allowing you to earn
            cash for your personal or company needs.
          </p>
        )}
        <p className="card-text">
          <strong>
            🤩 Ready to become a {appConfig.displayName} affiliate supporter?
            Join here and spread the Gospel... plus earn while we grow! 🙌
          </strong>
        </p>
        <div className={"d-flex gap-xs"}>
          <a
            href="https://display.church/affiliates/"
            className="btn btn-secondary"
            target="_becomeAffiliate"
          >
            LEARN MORE
          </a>
          {startNowBtn}
        </div>
      </div>
    </div>
  );
};

export default AffiliatePromoCard;
