/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2020.
 */

import React, { useContext, useEffect } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import EventBadges from "../EventBadges";
import EventHeaderTime from "./EventHeaderTime";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { formatDate, isSameDay } from "../../../../helpers/date";
import PrivateEventBadge from "../PrivateEventBadge";

const EventHeader = React.memo((props) => {
  const { event, embedded } = props;
  const calendarSettingsContext = useContext(WidgetSettingsContext);

  const showLocation =
    Number.parseInt(calendarSettingsContext["eLShowLocation"]) === 1;

  const startDate = new Date(event.start_time * 1000);
  const endDate = new Date(event.end_time * 1000);

  const isLastDays = !isSameDay(startDate, endDate);

  const endContainer = isLastDays ? (
    <div className="events-list--end-day-container">
      <h6>{formatDate(endDate, { day: "2-digit" })}</h6>
      <small>{formatDate(endDate, { month: "short" }).toLowerCase()}</small>
    </div>
  ) : null;

  const location =
    showLocation && event.display_location ? (
      <p>
        <i className="material-icons">location_on</i>
        {" " + event.display_location}
      </p>
    ) : null;

  const privateEventBadge =
    event.is_private * 1 == 1 && !embedded ? <PrivateEventBadge /> : null;

  const inlineStyles = {};

  if (props.useEventAccent && event.accentColor) {
    inlineStyles.backgroundColor = event.accentColor;
  }

  const eventBadges =
    (props.showRecurringBadge && event.recurrence_description) ||
    props.showFeaturedBadge ? (
      <EventBadges
        showRecurringBadge={
          props.showRecurringBadge && event.recurrence_description
        }
        showFeaturedBadge={props.showFeaturedBadge && event.is_featured}
      />
    ) : null;

  const content = props.showSummary ? (
    <div className="events-list--row">
      {privateEventBadge}

      <div className="events-list--date-container">
        <div className="events-list--day-container">
          <div className="dce__h1">
            {formatDate(startDate, { day: "2-digit" })}
          </div>
          <div className="dce__h4">
            {formatDate(startDate, { month: "short" })}
          </div>
        </div>

        <div className="events-list--end-date-container">{endContainer}</div>
      </div>

      <div>
        <div className="events-list--event-description">
          {eventBadges}
          <h2>{event.name}</h2>
          <EventHeaderTime
            startDate={startDate}
            endDate={endDate}
            isSpanningAcrossDays={isLastDays}
            showWeekDay={props.showWeekDay}
            weekDayFormatter={props.weekDayFormatter}
            isAllDayEvent={event.allDay}
          />
          {location}
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div
      className="events-list--row-wrapper"
      onClick={props.onClick}
      style={inlineStyles}
    >
      {content}
    </div>
  );
});

EventHeader.propTypes = {
  event: PropTypes.object,
  embedded: PropTypes.bool,
  onClick: PropTypes.func,
  showWeekDay: PropTypes.bool,
  weekDayFormatter: PropTypes.string,
  useEventAccent: PropTypes.bool,
  showSummary: PropTypes.bool,
  showRecurringBadge: PropTypes.bool,
  showFeaturedBadge: PropTypes.bool,
};

EventHeader.defaultProps = {
  showWeekDay: false,
  weekDayFormatter: "",
  useEventAccent: false,
  showSummary: true,
};

//Row.whyDidYouRender = true
export default EventHeader;
